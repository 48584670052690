import { useMemo } from 'react'
import { ProductTile, ProductTileProps } from './ProductTile'
import style from './ProductTilesChunks.module.sass'

export type ChunksProps = {
	products: ProductTileProps[]
}

export const ProductTilesChunks: React.FunctionComponent<ChunksProps> = ({ products }) => {
	const chunks = useMemo(() => {
		const chunks = []
		const chunkLength = 4
		let i = 0
		const n = products.length

		while (i < n) {
			const group = i + chunkLength
			chunks.push(products.slice(i, group))
			i = group
		}

		return chunks
	}, [products])

	return (
		<div className={style.wrapper}>
			{chunks.map((chunks, index) => (
				<div key={index} className={style.chunk}>
					{chunks.map((item) => (
						<div key={item.id} className={style.item}>
							<ProductTile {...item} />
						</div>
					))}
				</div>
			))}
		</div>
	)
}
