import React from 'react'
import style from './ThreeColor.module.sass'

export function ThreeColor(props: {
	fillColor?: string
	shadowColor?: string
	outlineColor?: string
	children: React.ReactNode
}) {
	return (
		<span
			className={style.Element}
			style={
				{
					'--ThreeColor-fillColor': props.fillColor,
					'--ThreeColor-shadowColor': props.shadowColor,
					'--ThreeColor-outlineColor': props.outlineColor,
				} as React.CSSProperties
			}>
			<span className={style.Text}>{props.children}</span>
		</span>
	)
}
