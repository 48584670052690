import React from 'react'
import { Container, ContainerProps } from '../Container'

export type BlockWrapperConfig = ContainerProps | { unwrapped: true }

export type BlockWrapperPath = { type: string; referenceType?: string }[]

export const BlockWrapperPathContext = React.createContext<BlockWrapperPath>([])

export function BlockWrapper(
	props: BlockWrapperConfig & {
		type: string
		referenceType?: string
		children: React.ReactNode
	}
) {
	const { type, referenceType, children } = props
	const context = React.useContext(BlockWrapperPathContext)
	const path = React.useMemo(
		() => [...context, { type, referenceType }],
		[context, type, referenceType]
	)
	let content = <>{children}</>

	if (context.length === 0) {
		if (!('unwrapped' in props)) {
			content = (
				<Container
					size={props.size}
					disableGutters={props.disableGutters}
					attrs={{
						'data-contember-type': type,
						'data-contember-reference-type': referenceType,
						...props.attrs,
					}}>
					{content}
				</Container>
			)
		}
	}

	return <BlockWrapperPathContext.Provider value={path}>{content}</BlockWrapperPathContext.Provider>
}
