import { LightboxOptions, useLightbox } from '@mangoweb/react-fslightbox'
import clsx from 'clsx'
import React from 'react'
import type { ImageResult } from '../data/fragments/ImageFragment'
import { ContemberImage } from './ContemberImage'
import style from './Gallery.module.sass'
import { Icon } from './Icon'

export type GalleryProps = {
	id?: string
	items: Array<{
		type: 'image' | 'video'
		image?: ImageResult
		video?: { url: string; poster: ImageResult }
	}>
}

export function Gallery(props: GalleryProps) {
	const options: LightboxOptions = {
		onClose: () => {
			window.dataLayer?.push({ event: 'gallery_close' })
			window.dataLayer?.push({
				page: {
					type: 'article',
				},
			})
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		onOpen: (lightbox) => {
			window.dataLayer?.push({
				gallery: {
					type: 'commonGallery',
					content: { id: props.id, imageCount: props.items.length },
					position: lightbox.stageIndexes.current + 1,
				},
				_clear: true,
			})
			window.dataLayer?.push({
				page: {
					type: 'articleGallery',
				},
				_clear: true,
			})
			window.dataLayer?.push({ event: 'page_body' })
			window.dataLayer?.push({ event: 'page_ready' })
			window.dataLayer?.push({ event: 'gallery_open' })
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		onSlideActivate: (current, previous) => {
			if (!previous) {
				//
			} else {
				let eventName = 'gallery_next'
				if (previous && (current.slideIndex + 1) % props.items.length === previous.slideIndex) {
					eventName = 'gallery_previous'
				}
				window.dataLayer?.push({ event: eventName, gallery: { position: current.slideIndex + 1 } })
			}
		},
	}

	const lightbox = useLightbox(
		props.items.map((item) => ({
			source: String(item.type === 'image' ? item.image?.url : item.video?.poster.url),
			type: 'image',
			caption: item.image?.description ? item.image?.description : '',
		})),
		options
	)

	const maxNumOfImagesDisplayed = 8

	return (
		<div
			style={
				{
					['--Gallery-extra-images']: props.items.length - (maxNumOfImagesDisplayed - 1),
				} as React.CSSProperties
			}
			className={clsx(
				style.wrapper,
				props.items.length > maxNumOfImagesDisplayed && style.overEight,
				props.items.length > 6 && style.overSix
			)}>
			<div className={style.inner}>
				{props.items.slice(0, maxNumOfImagesDisplayed).map((item, index) => {
					const isVideo = item.type === 'video'
					return (
						<button
							key={index}
							className={style.imageWrapper}
							onClick={() => {
								lightbox?.open(index)
							}}>
							{isVideo && <Icon name="play" />}
							<div className={style.image}>
								{!isVideo && item.image && (
									<ContemberImage image={item.image} objectFit="cover" layout="fill" />
								)}
								{isVideo && item.video && (
									<ContemberImage image={item.video.poster} objectFit="cover" layout="fill" />
								)}
							</div>
						</button>
					)
				})}
			</div>
		</div>
	)
}
