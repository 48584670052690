import {
	RichTextRenderer,
	RichTextRendererBlockProps,
	RichTextRendererProps,
} from '@contember/react-client'
import React from 'react'
import type { ContentBlock, ContentBlockReference } from '../../data/fragments/ContentFragment'
import { HighlightColor } from '../HighlightColor'
import { Link } from '../Link'
import { PlacedSticker } from '../PlacedSticker'
import { PlacedStickerCanvas } from '../PlacedStickerCanvas'
import { Textual } from '../Textual'
import { ThreeColor } from '../ThreeColor'
import { BlockWrapper, BlockWrapperConfig, BlockWrapperPathContext } from './BlockWrapper'
import { referenceRenderers } from './referenceRenderers'
import { wrapperConfigs } from './wrapperConfigs'

type SourceRichTextProps = { source: string }
type BlocksRichTextProps = { blocks: RichTextRendererBlockProps['blocks'] }

export type RichTextProps = { nonTextual?: boolean; userZone?: boolean } & (
	| SourceRichTextProps
	| BlocksRichTextProps
)

const renderLeaf: RichTextRendererProps['renderLeaf'] = (leaf) => {
	if (Number(process.env.LOG_LEVEL) > 4) {
		console.log('renderLeaf', leaf)
	}
	return leaf.fallback
}

export type AnchorElement = {
	type: 'anchor'
	href: string
}

export type InternalAnchorElement = {
	type: 'internalAnchor'
	href: string
}

export type HighlightColorElement = {
	type: 'highlightColor'
	suchThat: { fillColor?: string; backgroundColor?: string }
}

export type ThreeColorElement = {
	type: 'threeColor'
	suchThat: { fillColor?: string; outlineColor?: string; shadowColor?: string }
}

type CustomElement =
	| HighlightColorElement
	| ThreeColorElement
	| AnchorElement
	| InternalAnchorElement

const renderElement = (nonTextual = false, userZone = false) => {
	const RenderEl: RichTextRendererProps['renderElement'] = (element) => {
		if (Number(process.env.LOG_LEVEL) > 4) {
			console.log('renderElement', element)
		}

		const el = element.element as typeof element.element | CustomElement

		const elementType = el.type

		let content = element.fallback

		let textual = false

		let wrapperConfig: BlockWrapperConfig = {
			unwrapped: true,
		}

		if (el.type === 'anchor') {
			return <Link href={el.href}>{element.children}</Link>
		}

		if (el.type === 'internalAnchor') {
			const referene = element.reference as null | ContentBlockReference
			return referene?.link ? (
				<Link link={referene.link}>{element.children}</Link>
			) : (
				element.children
			)
		}

		if (elementType === 'highlightColor') {
			return <HighlightColor {...el.suchThat}>{element.children}</HighlightColor>
		}

		if (elementType === 'threeColor') {
			if (Number(process.env.LOG_LEVEL) > 4) {
				console.log('three', el.suchThat)
			}
			return <ThreeColor {...el.suchThat}>{element.children}</ThreeColor>
		}

		if (elementType === 'reference') {
			if (userZone) {
				wrapperConfig = {
					size: 'full',
					disableGutters: true,
				}
				if (element.referenceType === 'discussion') {
					return null
				}
			} else {
				wrapperConfig =
					wrapperConfigs[(element.referenceType ?? 'default') as keyof typeof wrapperConfigs] ??
					wrapperConfigs.default
			}
		} else {
			switch (elementType) {
				case 'heading':
					content = React.createElement(`h${Math.min(6, el.level + 1)}`, {}, element.children)
			}
			switch (elementType) {
				case 'horizontalRule':
					textual = true
					wrapperConfig = {
						unwrapped: true,
					}
					break
				default:
					textual = true
					wrapperConfig = {
						size: 'narrow',
						disableGutters: false,
					}
			}
		}

		if (textual && !nonTextual) {
			content = <Textual>{content}</Textual>
		}

		return (
			<BlockWrapper {...wrapperConfig} type={elementType} referenceType={element.referenceType}>
				{content}
			</BlockWrapper>
		)
	}

	RenderEl.displayName = 'RenderEl'

	return RenderEl
}

export function RichText(props: RichTextProps) {
	const blocks = ('blocks' in props ? props.blocks : null) as null | ContentBlock[]

	const blocksInfo = React.useMemo(() => {
		const blocksInfo: Record<string, ContentBlock | undefined> = {}
		blocks?.forEach((block) => {
			if (block.id) {
				blocksInfo[block.id] = block
			}
		})
		return blocksInfo
	}, [blocks])

	const renderEl = React.useMemo(() => {
		return renderElement(props.nonTextual, props.userZone)
	}, [props.nonTextual, props.userZone])

	return (
		<BlockInfoContext.Provider value={blocksInfo}>
			<RichTextRenderer
				sourceField="json"
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				renderBlock={BlockRenderer as any}
				renderLeaf={renderLeaf}
				renderElement={renderEl}
				referenceRenderers={referenceRenderers}
				{...props}
			/>
		</BlockInfoContext.Provider>
	)
}

const RichTextLiteContextValue = [{ type: 'lite' }]
export function RichTextLite(props: RichTextProps) {
	return (
		<BlockWrapperPathContext.Provider value={RichTextLiteContextValue}>
			<RichText {...props} nonTextual />
		</BlockWrapperPathContext.Provider>
	)
}

const BlockInfoContext = React.createContext<Record<string, ContentBlock | undefined>>({})

function BlockRenderer(props: { block: { id: string }; children?: React.ReactNode }) {
	const blockInfo = React.useContext(BlockInfoContext)[props.block.id]
	const hasStickers = (blockInfo?.stickers?.length ?? 0) > 0
	return hasStickers ? (
		<div style={{ position: 'relative' }}>
			{props.children}
			<PlacedStickerCanvas>
				{blockInfo?.stickers?.map((sticker) => (
					<PlacedSticker key={sticker.id} placedSticker={sticker} />
				))}
			</PlacedStickerCanvas>
		</div>
	) : (
		props.children
	)
}
