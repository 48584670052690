export function pruneObject<O extends Record<string, unknown>>(
	obj: O,
	options: { arrays?: boolean } = {}
): O {
	const arrays = options.arrays ?? false
	return Object.fromEntries(
		Object.entries(obj).filter(([_, v]) => {
			if (arrays && Array.isArray(v) && v.length === 0) {
				return false
			}
			return !(typeof v === 'undefined' || v === null)
		})
	) as O
}
