import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { richTextToPlaintext } from '../../libs/contember/richTextToPlaintext'
import type { ImageResult } from '../data/fragments/ImageFragment'
import type { PartnerResult } from '../data/fragments/PartnerFragment'
import type { PlacedStickerOrSurveyResult } from '../data/fragments/PlacedStickerOrSurveyFragment'
import type { UrlResult } from '../data/fragments/UrlFragment'
import { getBaseUrl } from '../utils/getBaseUrl'
import { AuthorBadge, AuthorBadgeProps } from './AuthorBadge'
import { ContemberImage } from './ContemberImage'
import { Link } from './Link'
import { PlacedSticker } from './PlacedSticker'
import { MaybeRichText } from './RichText/MaybeRichText'
import type { TagProps } from './Tag'
import { TagGrid } from './TagGrid'
import style from './Tile.module.sass'
import { TileGridContext } from './TileGrid'
import { FormattedDate } from './FormattedDate'

export interface TileGtmProps {
	id: string
	title?: string
	author?: string
	list: string
	position?: number
}

export const TileStyleContext = React.createContext<TileStyleProps>({})

export const largeOnMobile: TileStyleProps = {
	largeOnMobile: true,
}

export const smallOnDesktop: TileStyleProps = {
	smallOnDesktop: true,
}

export const smallTitle: TileStyleProps = {
	smallTitle: true,
}

export type TileStyleProps = {
	largeOnMobile?: boolean
	smallOnDesktop?: boolean
	smallTitle?: boolean
}

export type TileProps = {
	image?: ImageResult
	title: string
	highlighted?: boolean
	description?: ReactNode
	author?: AuthorBadgeProps
	imageAspectRatio?: 'square' | 'portrait'
	url?: UrlResult
	tags?: Array<TagProps>
	stickers?: Array<PlacedStickerOrSurveyResult>
	attrs?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
		Record<`data-${string}`, string>
	promoPartner?: PartnerResult
	gtm?: TileGtmProps
	htmlId?: string
	publishAt?: string
	className?: string
	hasDate?: boolean
} & TileStyleProps
export function Tile(props: TileProps) {
	const imageAspectRatio = (() => {
		if (props.imageAspectRatio) {
			return props.imageAspectRatio
		}
		if (props.image?.width && props.image.height) {
			const ratio = props.image.height / props.image.width
			if (ratio > 1.1) {
				return 'portrait'
			}
		}
		return 'square'
	})()

	const tileStyle = React.useContext(TileStyleContext)
	const { perLine } = React.useContext(TileGridContext)

	const largeOnMobile = props.largeOnMobile ?? tileStyle.largeOnMobile
	const smallOnDesktop = props.smallOnDesktop ?? tileStyle.smallOnDesktop
	const smallTitle = props.smallTitle ?? tileStyle.smallTitle

	const ratioClassName = imageAspectRatio === 'square' ? style.square : style.portrait

	const sizes = React.useMemo(() => {
		if (smallOnDesktop) {
			return `140px`
		}
		if (largeOnMobile) {
			if (perLine === 2) {
				return `(min-width: 480px) 50vw, (min-width: 1280px) 640px, 100vw`
			}
			return `(min-width: 480px) 50vw, (min-width: 700px) 33.333vw, (min-width: 1280px) 320px, 100vw`
		}

		if (perLine === 2) {
			return `(min-width: 480px) 50vw, (min-width: 1280px) 640px, 140px`
		}
		return `(min-width: 480px) 50vw, (min-width: 700px) 33.333vw, (min-width: 1280px) 320px, 140px`
	}, [largeOnMobile, smallOnDesktop, perLine])

	return (
		<div
			{...props.attrs}
			id={props.htmlId}
			className={clsx(
				style.Wrapper,
				ratioClassName,
				largeOnMobile && style.large,

				smallOnDesktop && style.small,
				smallTitle && style.smallTitle,
				props.className && props.className
			)}
			data-track-list={
				props.gtm &&
				JSON.stringify({
					item: {
						elementId: `article-${props.gtm.id}`,
						type: props.promoPartner ? 'promoArticle' : 'article',
						list: props.gtm.list,
						position: props.gtm.position,
						id: props.gtm.id,
						title: props.gtm.title || props.title,
						author: [(props.author && props.author?.name) || props.gtm.author],
						category: props.tags?.map((tag) => {
							return tag.text
						}),
						systemId: 'fomo',
						url: getBaseUrl() + props.url?.url,
					},
				})
			}
			data-track-elementid={props.gtm && `article-${props.gtm.id}`}>
			{props.url && (
				<Link className={style.Link} url={props.url} ariaLabel={richTextToPlaintext(props.title)} />
			)}
			{props.image && (
				<div className={style.ImageWithOverlays}>
					<div className={style.ImageWrapper}>
						<div className={style.ImageCrop}>
							<div className={style.Image}>
								<ContemberImage image={props.image} objectFit="cover" layout="fill" sizes={sizes} />
							</div>
						</div>
					</div>
					<div className={style.Stickers}>
						{props.stickers?.map((sticker) => (
							<PlacedSticker key={sticker.id} placedSticker={sticker} />
						))}
					</div>
				</div>
			)}
			{props.tags && (
				<div className={style.Tags}>
					<TagGrid items={props.tags} />
				</div>
			)}
			<div className={style.Content}>
				<h3 className={clsx(style.Heading, props.highlighted && style.HeadingHiglighted)}>
					<span>
						<MaybeRichText>{props.title}</MaybeRichText>
					</span>
				</h3>
				{props.description && <article className={style.Description}>{props.description}</article>}
				{props.author && (
					<AuthorBadge
						prefix={
							props.hasDate ? (
								<>
									{props.publishAt && <FormattedDate date={props.publishAt} />}
									{` by `}
								</>
							) : (
								''
							)
						}
						{...props.author}
					/>
				)}
			</div>
		</div>
	)
}
