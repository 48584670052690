import clsx from 'clsx'
import type { ImageResult } from '../data/fragments/ImageFragment'
import type { LinkResult } from '../data/fragments/LinkFragment'
import { head } from '../images'
import { mockId } from '../mocks/mockId'
import { Button } from './Button'
import { ContemberImage } from './ContemberImage'
import style from './ProductOfTheMonth.module.sass'

export type ProductOfTheMonthProps = {
	title?: string
	subtitle?: string
	image?: ImageResult
	linkLabel?: string
	link?: LinkResult
	noHeader?: boolean
	articleLocated?: boolean
}

export const ProductOfTheMonth: React.FunctionComponent<ProductOfTheMonthProps> = ({
	title,
	subtitle,
	image,
	linkLabel,
	noHeader,
	articleLocated,
	link,
}) => {
	return (
		<>
			{!noHeader && (
				<div className={style.productOfTheMonthTitleWrapper}>
					<div className={style.imageHead}>
						<ContemberImage
							image={{
								id: mockId(),
								size: 30,
								width: head.default.width,
								height: head.default.height,
								url: head.default.src,
							}}
						/>
					</div>
					<h3 className={style.productOfTheMonthTitle}>PRODUCT OF THE MONTH</h3>
					<div className={clsx(style.imageHead, style.imageHeadReverted)}>
						<ContemberImage
							reverted
							image={{
								id: mockId(),
								size: 30,
								width: head.default.width,
								height: head.default.height,
								url: head.default.src,
							}}
						/>
					</div>
				</div>
			)}
			<div className={clsx(style.wrapper, articleLocated && style.articleLocated)}>
				{image && (
					<div className={style.image}>
						<ContemberImage image={image} />
					</div>
				)}
				<div className={style.contentIn}>
					<div className={style.sponsored}>#sponsored</div>
					<h3 className={style.productName}>{title}</h3>
					<div className={style.productText}>{subtitle}</div>
					{link && (
						<div className={style.productButton}>
							<Button type="link" view="light" link={link}>
								<div>{linkLabel}</div>
							</Button>
						</div>
					)}
				</div>
				{image && (
					<div className={clsx(style.image, style.imageSecond)}>
						<ContemberImage image={image} />
					</div>
				)}
			</div>
		</>
	)
}
