import React from 'react'
import { isRichText } from '../../../libs/contember/richTextToPlaintext'
import { RichText } from './RichText'

export function MaybeRichText({ children }: { children?: string | null }) {
	const isRichTextString = React.useMemo(() => {
		return isRichText(children ?? '')
	}, [children])

	return <>{isRichTextString ? <RichText source={children ?? ''} /> : children}</>
}
