import { ProductTile } from './ProductTile'
import style from './ProductTiles.module.sass'
import { ChunksProps, ProductTilesChunks } from './ProductTilesChunks'

export type ProductTilesProps = ChunksProps & TypeView

type TypeView = {
	typeView?: 'grid' | 'slider'
}

export const ProductTiles: React.FunctionComponent<ProductTilesProps> = ({
	products,
	typeView,
}) => {
	const sliderContent = <ProductTilesChunks products={products} />
	const gridContent = (
		<>
			{products.map((product) => (
				<ProductTile key={product.id} {...product} />
			))}
		</>
	)
	return (
		<div className={style.wrapper}>
			{typeView === 'slider' ? (
				sliderContent
			) : typeView === 'grid' ? (
				<div className={style.main}>{gridContent}</div>
			) : (
				<>
					<div className={style.viewMobile}>{sliderContent}</div>
					<div className={style.viewDesktop}>
						<div className={style.main}>{gridContent}</div>
					</div>
				</>
			)}
		</div>
	)
}
