import React from 'react'

const containerStyles: React.CSSProperties = {
	position: 'absolute',
	overflow: 'hidden',
	left: 0,
	right: 0,
	top: '-5em',
	bottom: '-5em',
	pointerEvents: 'none',
}

const innerStyles: React.CSSProperties = {
	position: 'absolute',
	left: 0,
	right: 0,
	top: '5em',
	bottom: '5em',
}

export function PlacedStickerCanvas(props: { children: React.ReactNode }) {
	return (
		<div style={containerStyles}>
			<div style={innerStyles}>{props.children}</div>
		</div>
	)
}
