import clsx from 'clsx'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useStorageBackedState } from 'use-storage-backed-state'
import { createFetcher } from '../../libs/next/api/jsonApiHandler'
import type { API } from '../../pages/api/v1/[handler]'
import type { ImageResult } from '../data/fragments/ImageFragment'
import style from './BinaryPoll.module.sass'
import { Draggable } from './Draggable'
import { ImgixImage } from './ImgixImage'

const api = createFetcher<API>('/api/v1/[handler]')

const submitVote = (answerId: string) => api('vote', { answer: answerId })

type Side = {
	answerId: string
	textualAnswer?: string
	imageAnswer?: ImageResult
	backgroundColor?: string
	voteCount: number
}

export type BinaryPollProps = {
	id: string
	icon?: ImageResult
	question?: string
	image?: ImageResult
	textColor?: string
	sideA: Side
	sideB: Side
	alreadyVoted?: boolean
}

const useVotesCount = (
	id: string,
	sideAVotesFromProps: number,
	sideBVotesFromProps: number,
	voted: VotedSide
) => {
	const sessionStorage = 'window' in globalThis ? window.sessionStorage : undefined
	const [cachedSideAVotes, setCachedSideAVotes] = useStorageBackedState(
		sideAVotesFromProps,
		`binary-poll-sideA-${id}`,
		sessionStorage
	)
	const [cachedSideBVotes, setCachedSideBVotes] = useStorageBackedState(
		sideBVotesFromProps,
		`binary-poll-sideB-${id}`,
		sessionStorage
	)

	useEffect(() => {
		const handleSide = (
			name: 'sideA' | 'sideB',
			cachedVotes: number,
			votesFromProps: number,
			setCached: (count: number) => void
		) => {
			if (voted === name && cachedVotes <= votesFromProps) {
				setCached(votesFromProps + 1)
			}
		}
		handleSide('sideA', cachedSideAVotes, sideAVotesFromProps, setCachedSideAVotes)
		handleSide('sideB', cachedSideBVotes, sideBVotesFromProps, setCachedSideBVotes)
	}, [
		cachedSideAVotes,
		cachedSideBVotes,
		setCachedSideAVotes,
		setCachedSideBVotes,
		sideAVotesFromProps,
		sideBVotesFromProps,
		voted,
	])

	return {
		sideAVotes: Math.max(sideAVotesFromProps, cachedSideAVotes),
		sideBVotes: Math.max(sideBVotesFromProps, cachedSideBVotes),
	}
}

type VotedSide = false | 'sideA' | 'sideB'

export const BinaryPoll: FunctionComponent<BinaryPollProps> = ({
	id,
	icon,
	question,
	image,
	textColor,
	sideA,
	sideB,
	alreadyVoted = false,
}) => {
	const [showResults, setShowResults] = useStorageBackedState(alreadyVoted, `binary-poll-${id}`)
	const [voted, setVoted] = useState<VotedSide>(false)
	const { sideAVotes, sideBVotes } = useVotesCount(id, sideA.voteCount, sideB.voteCount, voted)
	const ratio = useMemo(() => {
		if (!voted && !showResults) {
			return 0.5
		}
		if (sideAVotes + sideBVotes === 0) {
			return 0.5
		}
		// @TODO: možná zohlednit, že kraje kruhu mají menší objem
		return sideAVotes / (sideAVotes + sideBVotes)
	}, [voted, sideAVotes, sideBVotes, showResults])

	const vote = useCallback(
		(side: 'sideA' | 'sideB') => {
			if (!voted) {
				setVoted(side)
				switch (side) {
					case 'sideA':
						submitVote(sideA.answerId).then(console.log, console.error)
						break
					case 'sideB':
						submitVote(sideB.answerId).then(console.log, console.error)
						break
				}
			}
		},
		[sideA.answerId, sideB.answerId, voted]
	)

	useEffect(() => {
		if (voted !== false) {
			setShowResults(true)
		}
	}, [setShowResults, voted])

	return (
		<Draggable>
			<div
				className={clsx(
					style.wrapper,
					showResults && style.is_results,
					voted === 'sideA' && style.is_voted_A,
					voted === 'sideB' && style.is_voted_B
				)}
				style={
					{
						['--BinaryPoll-textColor']: textColor && `${textColor}`,
						['--BinaryPoll-ratio']: `${ratio}`,
						['--BinaryPoll-sideA-backgroundColor']:
							sideA.backgroundColor && `${sideA.backgroundColor}`,
						['--BinaryPoll-sideB-backgroundColor']:
							sideB.backgroundColor && `${sideB.backgroundColor}`,
					} as React.CSSProperties
				}>
				<div className={style.background} />
				<div className={style.answerButtons}>
					{!voted &&
						!showResults &&
						[sideA, sideB].map((side, i) => (
							<button
								key={i}
								className={style.answerButton}
								type="button"
								aria-label={side.textualAnswer}
								onClick={() => vote(i === 0 ? 'sideA' : 'sideB')}
								data-track-click={JSON.stringify({
									event: 'clickTo_content',
									clickTo: 'BinaryPoll',
									question: question,
									answer: side.textualAnswer,
								})}
							/>
						))}
				</div>
				<div className={style.in}>
					<div className={style.question}>
						{icon && (
							<div className={style.questionIcon}>
								<ImgixImage
									src={icon.url}
									width={icon.width || 1}
									height={icon.height || 1}
									alt={icon.alt ?? ''}
								/>
							</div>
						)}
						{question}
						{image && (
							<div className={style.questionImage}>
								<ImgixImage
									src={image.url}
									width={image.width || 1}
									height={image.height || 1}
									alt={image.alt ?? ''}
								/>
							</div>
						)}
					</div>
					<div className={style.answers}>
						{[sideA, sideB].map((side, i) => (
							<div
								key={i}
								className={clsx(style.answer, i === 0 ? style.is_sideA : style.is_sideB)}>
								<div className={style.answerScore}>
									<div className={style.answerScoreIn}>
										{Math.abs(Math.round(ratio * 100) - i * 100)}%
									</div>
								</div>
								{side.imageAnswer ? (
									<div className={style.answerImage}>
										{/* @TODO: limit size */}
										<ImgixImage
											src={side.imageAnswer.url}
											layout="fill"
											objectFit="contain"
											alt={side.textualAnswer ?? ''}
										/>
									</div>
								) : (
									<div className={style.answerText}>
										{side.textualAnswer ?? (i === 0 ? 'A' : 'B')}
									</div>
								)}
							</div>
						))}
					</div>
				</div>
			</div>
		</Draggable>
	)
}
