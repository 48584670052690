import React from 'react'
import type { ProductResult } from '../data/fragments/ProductFragment'
import { AuthorBadge, AuthorBadgeProps } from './AuthorBadge'
import { Button } from './Button'
import { ContemberImage } from './ContemberImage'
import style from './ProductTile.module.sass'

export type ProductTileProps = ProductResult

export function ProductTile({ id, link, image, name, price, shop }: ProductTileProps) {
	const externalLink = shop?.link?.externalLink ?? ''
	const label = React.useMemo(() => {
		const domain = externalLink.replace(/^https?:\/\/(www\.)?/, '').replace(/\/.*$/, '')
		if (domain) {
			return `Koupit na ${domain}`
		}
		return 'Koupit'
	}, [externalLink])

	const shopAuthor = React.useMemo<null | AuthorBadgeProps>(() => {
		if (shop) {
			return {
				id: shop.id,
				name: shop.name,
				avatar: shop.icon,
				nick: '',
			}
		}
		return null
	}, [shop])

	return (
		<div
			className={style.wrapper}
			data-track-list={JSON.stringify({
				item: {
					type: 'product',
					product_id: id,
				},
			})}>
			{!!(link && label) && (
				<div className={style.button}>
					<Button type="link" link={link} view="dark">
						{label}
						<div className={style.buttonIn} />
					</Button>
				</div>
			)}
			{image && (
				<div className={style.image}>
					<div className={style.imageIn}>
						<ContemberImage image={image} layout="fill" objectFit="cover" />
					</div>
				</div>
			)}
			{name && <h3 className={style.title}>{name}</h3>}
			{price && <div className={style.price}>{formatPrice(price)}</div>}
			{shopAuthor && (
				<div className={style.author}>
					<AuthorBadge {...shopAuthor} />
				</div>
			)}
		</div>
	)
}

function formatPrice(price: number) {
	return <>{(price / 100).toFixed(2).replace('.00', ',-').replace('.', ',')} Kč</>
}
