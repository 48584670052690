import clsx from 'clsx'
import { useImageSizes } from '../utils/ImageSizesContext'
import style from './AuthorBadge.module.sass'
import { ContemberImage } from './ContemberImage'
import { Link } from './Link'
import type { AuthorResult } from '../data/fragments/AuthorFragment'

export type AuthorBadgeProps = AuthorResult & {
	prefix?: React.ReactNode
}

export function AuthorBadge(props: AuthorBadgeProps) {
	const sizes = useImageSizes('AuthorBadge') ?? '39px'

	const label = (
		<>
			{props.prefix ?? 'by '}
			<span className={style.name}>{props.nick ?? props.name}</span>
		</>
	)

	return (
		<>
			{props.url ? (
				<Link
					url={props.url}
					className={clsx(style.wrapper, style.view_link)}
					dataAttributes={{ 'track-list': 'false' }}>
					<div className={style.image}>
						<div className={style.imageIn}>
							{props.avatar && (
								<ContemberImage
									image={props.avatar}
									layout="fill"
									objectFit="cover"
									sizes={sizes}
								/>
							)}
						</div>
					</div>
					<span className={style.label}>{label}</span>
				</Link>
			) : (
				<div className={style.wrapper}>
					{props.avatar && (
						<div className={style.image}>
							<ContemberImage image={props.avatar} objectFit="cover" sizes={sizes} />
						</div>
					)}
					<span className={style.label}>{label}</span>
				</div>
			)}
		</>
	)
}
