import clsx from 'clsx'
import React from 'react'
import style from './HighlightColor.module.sass'

export const emptyColorsArray: string[] = []
export const whiteColorsArray: string[] = ['#fff', '#ffffff', 'white']

export const HighlightColorTransparentBackgroundColor =
	React.createContext<string[]>(whiteColorsArray)

export function HighlightColorPreset(props: {
	children: React.ReactNode
	transparentWhite: boolean
}) {
	return (
		<HighlightColorTransparentBackgroundColor.Provider
			value={props.transparentWhite ? whiteColorsArray : emptyColorsArray}>
			{props.children}
		</HighlightColorTransparentBackgroundColor.Provider>
	)
}

export function HighlightColor(props: {
	fillColor?: string
	backgroundColor?: string
	children: React.ReactNode
}) {
	const transparentColors = React.useContext(HighlightColorTransparentBackgroundColor)
	const backgroundColor =
		transparentColors.indexOf(props.backgroundColor ?? 'transparent') > -1
			? 'transparent'
			: props.backgroundColor

	const hasBackground = backgroundColor && backgroundColor !== 'transparent'

	return (
		<span
			data-bgc={backgroundColor}
			data-hbgc={hasBackground}
			className={clsx(style.Element, hasBackground && style.hasBackground)}
			style={
				{
					'--HighlightColor-fillColor': props.fillColor,
					'--HighlightColor-backgroundColor': backgroundColor,
				} as React.CSSProperties
			}>
			<span className={style.Text}>{props.children}</span>
		</span>
	)
}
