import { createContext, FunctionComponent, useContext } from 'react'
import type { ArticleWithContentResult } from '../data/fragments/ArticleWithContentFragment'

const ArticleContext = createContext<null | ArticleWithContentResult>(null)

export const ArticleContextProvider: FunctionComponent<{ value: ArticleWithContentResult }> = ({
	value,
	children,
}) => <ArticleContext.Provider value={value}>{children}</ArticleContext.Provider>

export const useArticle = () => {
	const context = useContext(ArticleContext)

	if (!context) {
		throw new Error('Missing article')
	}

	return context
}

export const useAuthors = () => {
	const article = useArticle()
	return article.authors
}
