import React from 'react'
import { IframelyContext } from '../utils/iframely'

export function OEmbed({ oembed }: { oembed: string }) {
	const iframely = React.useContext(IframelyContext)

	const params = React.useMemo(() => {
		try {
			const parsed = JSON.parse(oembed) as null | { html?: string }
			if (parsed && typeof parsed === 'object') {
				if ('html' in parsed && typeof parsed.html === 'string') {
					return { __html: parsed.html }
				}
			}
		} catch (e) {}
		return null
	}, [oembed])

	if (!params) {
		return null
	}

	return <div key={iframely ? 'loaded' : 'not-loaded'} dangerouslySetInnerHTML={params} />
}
