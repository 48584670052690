import clsx from 'clsx'
import React from 'react'
import { StickerSize } from '../../generated/content'
import type { StickerResult } from '../data/fragments/StickerFragment'
import { ContemberImage } from './ContemberImage'
import { Draggable } from './Draggable'
import { Link } from './Link'
import style from './Sticker.module.sass'

export function Sticker(props: { sticker: StickerResult }) {
	const size = props.sticker.size ?? StickerSize.small
	let content = (
		<>
			{props.sticker.icon && (
				<ContemberImage image={props.sticker.icon} layout="fill" objectFit="contain" />
			)}
		</>
	)

	if (props.sticker.link) {
		content = (
			<Link className={style.link} link={props.sticker.link}>
				{content}
			</Link>
		)
	}

	return (
		<Draggable>
			<div
				className={clsx(style.wrapper, style[`size_${size}`])}
				style={
					{
						'--Sticker-backgroundColor': props.sticker.backgroundColor ?? 'transparent',
					} as React.CSSProperties
				}>
				<div className={style.sticker}>{content}</div>
			</div>
		</Draggable>
	)
}
