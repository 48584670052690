import React from 'react'
import { format, parseISO } from 'date-fns'

const FORMAT = 'd.-L.-yyyy'

export function FormattedDate({ date }: { date: string }) {
	const formatted = React.useMemo(() => {
		const now = format(new Date(), FORMAT).split('-')
		const presenting = format(parseISO(date), FORMAT).split('-')

		if (now[2] === presenting[2]) {
			presenting.pop()
		}

		return presenting.join('\xa0')
	}, [date])
	return <time dateTime={date}>{formatted}</time>
}
