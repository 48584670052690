import clsx from 'clsx'
import type { ReactNode } from 'react'
import React from 'react'
import { Button } from './Button'
import style from './TileGrid.module.sass'

export const TileGridContext = React.createContext({ perLine: 4 })

export type TileGridProps = {
	items: Array<ReactNode>
	perLine?: 2 | 4
	className?: string
	slidingView?: boolean
	pagedBy?: number
}

export function TileGrid(props: TileGridProps) {
	const perLine = props.perLine ?? 4
	const context = React.useMemo(() => {
		return { perLine }
	}, [perLine])
	const pagedBy = props.pagedBy ?? null
	const totalItems = props.items.length
	const [numberShowing, setNumberShowing] = React.useState<number | null>(pagedBy)
	return (
		<TileGridContext.Provider value={context}>
			<div
				className={clsx(
					style.Wrapper,
					style['perLine_' + perLine],
					props.slidingView && style.viewSlide
				)}>
				{props.items.map((item, index) => (
					<div
						key={index}
						className={clsx(style.Item, numberShowing && index + 1 > numberShowing && style.hide)}>
						{item}
					</div>
				))}
			</div>
			{pagedBy && numberShowing && numberShowing < totalItems && (
				<div className={style.loadMore}>
					<Button
						onClick={() => {
							setNumberShowing(numberShowing + pagedBy)
						}}
						type="button">
						Načíst další
					</Button>
				</div>
			)}
		</TileGridContext.Provider>
	)
}
