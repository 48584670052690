import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import styles from './Draggable.module.sass'

export const Draggable: FunctionComponent = ({ children }) => {
	const [isMoving, setIsMoving] = useState(false)
	const startPosition = useRef({ x: 0, y: 0 })
	const [offsetPosition, setOffsetPosition] = useState({ x: 0, y: 0 })
	const [inertia, setInertia] = useState({ x: 0, y: 0 })

	const onPointerDown = useCallback(
		(event: React.PointerEvent<HTMLDivElement>) => {
			startPosition.current = {
				x: event.clientX - offsetPosition.x,
				y: event.clientY - offsetPosition.y,
			}
			setIsMoving(true)
		},
		[offsetPosition]
	)
	const onPointerUp = useCallback((event: React.PointerEvent<HTMLDivElement>) => {
		event.currentTarget.releasePointerCapture(event.pointerId)
		setIsMoving(false)
	}, [])
	const onPointerMove = useCallback(
		(event: React.PointerEvent<HTMLDivElement>) => {
			event.preventDefault()

			const newPosition = { x: event.clientX, y: event.clientY }
			const newOffsetPosition = {
				x: newPosition.x - startPosition.current.x,
				y: newPosition.y - startPosition.current.y,
			}

			if (newOffsetPosition.x !== 0 || newOffsetPosition.y !== 0) {
				event.currentTarget.setPointerCapture(event.pointerId)
			}

			setInertia({
				x: newOffsetPosition.x - offsetPosition.x,
				y: newOffsetPosition.y - offsetPosition.y,
			})
			setOffsetPosition(newOffsetPosition)
		},
		[offsetPosition]
	)

	useEffect(() => {
		if (!isMoving && Math.abs(inertia.x) > 0.1 && Math.abs(inertia.y) > 0.1) {
			requestAnimationFrame(() => {
				setOffsetPosition({
					x: offsetPosition.x + inertia.x,
					y: offsetPosition.y + inertia.y,
				})
				setInertia({
					x: inertia.x * (7 / 10),
					y: inertia.y * (7 / 10),
				})
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMoving, inertia])

	return (
		<div
			className={styles.wrapper}
			onPointerDown={onPointerDown}
			onPointerUp={onPointerUp}
			onPointerMove={isMoving ? onPointerMove : undefined}
			style={
				{
					'--Draggable-offset-x': `${offsetPosition.x}px`,
					'--Draggable-offset-y': `${offsetPosition.y}px`,
				} as React.CSSProperties
			}>
			<div className={styles.in}>{children}</div>
		</div>
	)
}
