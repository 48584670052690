import type { RichTextRendererBlockProps } from '@contember/react-client'
import type { ContentBlockReference } from '../../data/fragments/ContentFragment'
import type { BlockWrapperConfig } from './BlockWrapper'

type SourceRichTextProps = { source: string }
type BlocksRichTextProps = { blocks: RichTextRendererBlockProps['blocks'] }

export type RichTextProps = SourceRichTextProps | BlocksRichTextProps

export const wrapperConfigs: Partial<Record<ContentBlockReference['type'], BlockWrapperConfig>> & {
	default: BlockWrapperConfig
} = {
	embed: {
		size: 'narrow',
		disableGutters: false,
	},
	image: {
		size: 'full',
		disableGutters: true,
	},
	discussion: {
		size: 'full',
		disableGutters: true,
	},
	quote: {
		size: 'narrow',
		disableGutters: false,
	},
	feed: {
		unwrapped: true,
	},
	default: {
		size: 'narrow',
		disableGutters: false,
	},
}
