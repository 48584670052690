import type { PartnerResult } from '../data/fragments/PartnerFragment'
import { ContemberImage } from './ContemberImage'
import style from './PromoCodes.module.sass'
import * as React from 'react'
import clsx from 'clsx'
export function PromoCodes(props: {
	codes: Array<{ code?: string; saleText?: string; partner?: PartnerResult }>
}) {
	const [copiedCode, setCopiedCode] = React.useState<number | null>(null)
	const copyText = async (text: string) => {
		if ('clipboard' in navigator) {
			return await navigator.clipboard.writeText(text)
		} else {
			return document.execCommand('copy', true, text)
		}
	}
	return (
		<div className={style.wrapper}>
			<ul className={style.list}>
				{props.codes.map((item, index) => {
					return (
						<>
							{item.partner && (
								<li className={style.listItem} key={index}>
									<div className={style.image}>
										{item.partner.icon && (
											<ContemberImage objectFit="cover" image={item.partner.icon} />
										)}
									</div>
									<div className={style.text}>
										<h2 className={style.partnerTitle}>{item.partner.name}</h2>
										<p className={style.saleText}>{item.saleText}</p>
									</div>
									<div className={style.code}>
										<p className={style.codeText}>{item.code}</p>
										<button
											className={clsx(style.codeButton, copiedCode === index && style.copied)}
											onClick={() => {
												console.log('delej')
												if (item.code) {
													copyText(item.code).then(() => {
														setCopiedCode(index)
													})
												}
											}}></button>
									</div>
								</li>
							)}
						</>
					)
				})}
			</ul>
		</div>
	)
}
