import React from 'react'
import type { PlacedStickerOrSurveyResult } from '../data/fragments/PlacedStickerOrSurveyFragment'
import { surveyToBinaryPoll } from '../data/helpers/surveyToBinaryPoll'
import { BinaryPoll } from './BinaryPoll'
import style from './PlacedSticker.module.sass'
import { Sticker } from './Sticker'

export function PlacedSticker(props: { placedSticker: PlacedStickerOrSurveyResult }) {
	const { sticker, survey, locationX, locationY } = props.placedSticker

	const binaryPoll = React.useMemo(() => (survey ? surveyToBinaryPoll(survey) : null), [survey])

	return (
		<div
			className={style.wrapper}
			style={
				{
					'--PlacedSticker-left': `${locationX}%`,
					'--PlacedSticker-top': `${locationY}%`,
				} as CSSCustomProperties
			}>
			{sticker && (
				<div className={style.sticker}>
					<Sticker sticker={sticker} />
				</div>
			)}
			{binaryPoll && (
				<div className={style.binaryPoll}>
					<BinaryPoll {...binaryPoll} />
				</div>
			)}
		</div>
	)
}
