import type { SurveyResult } from '../fragments/SurveyFragment'
import type { BinaryPollProps } from '../../components/BinaryPoll'

export function surveyToBinaryPoll(survey: SurveyResult): BinaryPollProps | null {
	if (survey.answers.length === 2) {
		return {
			id: survey.id,
			question: survey.primaryText,
			image: survey.image,
			// icon?
			textColor: survey.textColor,
			sideA: {
				answerId: survey.answers[0].id,
				textualAnswer: survey.answers[0].text,
				imageAnswer: survey.answers[0].image,
				backgroundColor: survey.answers[0].backgroundColor,
				voteCount: survey.answers[0].stats?.total ?? 0,
			},
			sideB: {
				answerId: survey.answers[1].id,
				textualAnswer: survey.answers[1].text,
				imageAnswer: survey.answers[1].image,
				backgroundColor: survey.answers[1].backgroundColor,
				voteCount: survey.answers[1].stats?.total ?? 0,
			},
			alreadyVoted: false,
		}
	}
	return null
}
