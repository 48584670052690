import Script from 'next/script'
import React from 'react'

export type Iframely = {
	load: () => void
	on: () => void
	openHref: () => void
	trigger: () => void
	triggerAsync: () => void
	widgets: {
		load: () => void
	}
}

export const IframelyContext = React.createContext<null | Iframely>(null)

export function WithIframely(props: { children: React.ReactNode }) {
	const [iframely, setIframely] = React.useState<null | Iframely>(null)

	return (
		<>
			<Script
				id="iframely-script"
				strategy="afterInteractive"
				onLoad={() => setIframely((window as unknown as { iframely: Iframely }).iframely)}
				src="https://cdn.iframe.ly/embed.js?key=046edfb9528df3a334031c"
			/>
			<IframelyContext.Provider value={iframely}>{props.children}</IframelyContext.Provider>
		</>
	)
}
