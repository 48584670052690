import clsx from 'clsx'
import React from 'react'
import { EmbedType } from '../../generated/content'
import { Dump } from '../../libs/dump'
import type { EmbedResult } from '../data/fragments/EmbedFragment'
import style from './Embed.module.sass'

function EmbedIframe(props: { src: string; width: number; height: number; type?: EmbedType }) {
	const iframeRef = React.useRef<HTMLIFrameElement | null>(null)
	const [runtimeIframeAspectRatio, setRuntimeIframeAspectRatio] = React.useState<string | null>(
		null
	)

	React.useEffect(() => {
		const callback = (event: MessageEvent) => {
			if (event.source === iframeRef.current?.contentWindow) {
				if (event.data['twttr.embed']?.method === 'twttr.private.resize') {
					const twitterResizePostMessage = event.data['twttr.embed'].params[0]
					const { width, height } = twitterResizePostMessage
					iframeRef.current.width = width
					iframeRef.current.height = height
					setRuntimeIframeAspectRatio(String(width / height))
					window.removeEventListener('message', callback)
				}
			}
		}
		window.addEventListener('message', callback)
		return () => {
			window.removeEventListener('message', callback)
		}
	}, [iframeRef])

	return (
		<div
			className={style.Wrapper}
			style={
				{
					['--Embed-max-width']: `${props.width}px`,
				} as React.CSSProperties
			}
			data-track-list={JSON.stringify({
				item: {
					type: 'embed',
					url: props.src,
				},
			})}>
			<div
				className={clsx(style.Iframe, props.type && style[props.type])}
				style={
					{
						['--Embed-aspect-ratio']: runtimeIframeAspectRatio
							? runtimeIframeAspectRatio
							: `${props.width / props.height}`,
					} as React.CSSProperties
				}>
				<iframe
					ref={iframeRef}
					src={props.src}
					referrerPolicy="no-referrer"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					loading="lazy"
					allowFullScreen
					width={props.width}
					height={props.height}
					frameBorder="0"></iframe>
			</div>
		</div>
	)
}

export function Embed(props: { embed: EmbedResult }) {
	let content: React.ReactNode = null

	const {
		embed: { type, embedId, embedType },
	} = props

	if (!embedId) {
		return null
	}

	switch (type) {
		case EmbedType.youtube:
			content = (
				<EmbedIframe
					src={`https://www.youtube-nocookie.com/embed/${encodeURIComponent(embedId)}`}
					width={560}
					height={315}
					type={type}
				/>
			)
			break
		case EmbedType.tiktok:
			content = (
				<EmbedIframe
					src={`https://www.tiktok.com/embed/${encodeURIComponent(embedId)}`}
					width={340}
					height={700}
					type={type}
				/>
			)
			break
		case EmbedType.instagram:
			content = (
				<EmbedIframe
					src={`https://www.instagram.com/p/${encodeURIComponent(embedId)}/embed/captioned/`}
					width={560}
					height={645}
					type={type}
				/>
			)
			break
		case EmbedType.twitter:
			content = (
				<EmbedIframe
					src={`https://platform.twitter.com/embed/Tweet.html?id=${encodeURIComponent(embedId)}`}
					width={560}
					height={315}
					type={type}
				/>
			)
			break
		case EmbedType.facebook:
			content = (
				<EmbedIframe
					width={560}
					height={500}
					src={`https://www.facebook.com/plugins/post.php?width=560&height=500&href=${encodeURIComponent(
						embedId
					)}`}
					type={type}
				/>
			)
			break
		case EmbedType.spotify:
			if (!embedType) {
				return null
			}
			content = (
				<EmbedIframe
					src={`https://open.spotify.com/embed/${encodeURIComponent(
						embedType
					)}/${encodeURIComponent(embedId)}`}
					width={300}
					height={380}
				/>
			)
			break
		case EmbedType.vimeo:
			content = (
				<EmbedIframe
					src={`https://player.vimeo.com/video/${encodeURIComponent(embedId)}`}
					width={560}
					height={315}
				/>
			)
			break
		default:
			content = (
				<div style={{ maxWidth: 200 }}>
					<Dump data={{ type, embedId, embedType }} />
				</div>
			)
	}

	return <>{content}</>
}
