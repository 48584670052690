import clsx from 'clsx'
import React from 'react'
import style from './Quote.module.sass'

export function Quote(props: {
	primary: React.ReactNode
	secondary?: React.ReactNode
	color?: string
}) {
	return (
		<figure
			className={clsx(style.wrapper, !props.color && style.colorless)}
			style={{ ['--Quote-color']: props.color } as React.CSSProperties}>
			<blockquote className={style.primary}>{props.primary}</blockquote>
			<figcaption className={style.secondary}>{props.secondary}</figcaption>
		</figure>
	)
}
